$lato:'Lato', sans-serif;
$white:#ffffff;

@mixin fill{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

body{
	font-family: $lato;
	h1,h2,h3,h4,h5,h6,p{
		margin: 0;
	}
	ul{
		list-style: none;
		margin: 0;
		padding: 0;
	}
	a{
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
}

div.page{
	overflow: hidden;
	position: relative;
	background-color: #e6e6e6;
}

div.vertical_height{
	@include fill;
	> div{
		display: table;
		width: 100%;
		height: 100%;
		> div{
			display: table-cell;
			vertical-align: middle;
			height: 100%;
		}
	}
}

div.default_font{
	font-size: 18px;
	line-height: 30px;
	h1,h2,h3,h4,h5{
		font-weight: 400;
	}
	h1{
		
	}
	h2{
		font-size: 48px;
		line-height: 57px;
	}
	h3{
		font-size: 36px;
		line-height: 30px;
	}
	h4{
		font-size: 24px;
		//line-height: 30px;
		line-height: 36px;
	}
	h5{
		font-size: 22px;
		line-height: 30px;
	}
	h6{
		font-size: 20px;
		line-height: 30px;
	}
	p{
		padding-bottom: 44px;
	}
	table{
		td{
			padding:20px;
		}
		th{
			padding:20px;
		}
	}
}

div.text_desc{
	div.default_font{
		h1,h2,h3,h4,h5,h6,p,ul,a{
			color: #252424;
			padding-bottom: 22px;
			&:last-child{
				padding-bottom: 0;
			}
			a{
				color: #252424;
				font-size: 18px;
				text-decoration: underline;
			}
		}
		ul{
			list-style: square;
			padding-left: 35px;
			li{
				//padding-left: 14px;
				//background-repeat: no-repeat;
				//background-position: left top 12px;
				//background-image: url('./../img/list_point.png');
			}
		}
	}
}

header.header{
	position: absolute;
	top: 26px;
	left: 0;
	width: 100%;
	z-index: 3;
	//overflow: hidden;
	div.logo_box{
		position: relative;
	}
	div.menu_box{
		padding-top: 27px;
		nav.menu{
			> ul{
				position: relative;
				&:after{
					content: '';
					display: block;
					width: 200%;
					top: 100%;
					left: 0;
					border-bottom: 2px solid $white;
				}
				> li{
					display: inline-block;
					vertical-align: top;
					padding-bottom: 30px;
					margin-right: 6.8%;
					> a{
						color: $white;
						font-weight: 500;
						font-size: 18px;
						text-transform: uppercase;
						position: relative;
						cursor: pointer!important;
						&:after{
							content:"";
							display:block;
							width:0%;
							position: absolute;
							left:0;
							right: 0;
							margin-left: auto;
							margin-right: auto;
							border-bottom:8px solid #fff;
							transition:0.3s ease;
							-o-transition:0.3s ease;
							-webkit-transition:0.3s ease;
							-moz-transition:0.3s ease;
							-ms-transition:0.3s ease;
							bottom:-34.5px;
						}
						&:hover{
							&:after{
								left:0;
								width:50px;
							}
						}
					}
					> ul{
						display: none;
						position: absolute;
						top: 100%;
						left: 0;
						padding-top: 27px;
						> li{
							display: inline-block;
							vertical-align: top;
							margin-right: 4%;
							margin-bottom: 1.7%;
							&:last-child{
								margin-right: 0;
							}
							> a{
								color: $white;
								font-weight: 500;
								font-size: 18px;
								text-transform: uppercase;
								position: relative;
							}
						}
					}
					/*&:hover{
						> ul{
							display: block;
						}
						
					}*/
					&:last-child{
						margin-right: 0;
					}
					> a{
						color: $white;
						font-weight: 500;
						font-size: 22px;
						text-transform: uppercase;
						position: relative;
						&:after{
							content:"";
							display:block;
							width:0%;
							position: absolute;
							left:0;
							right: 0;
							margin-left: auto;
							margin-right: auto;
							border-bottom:8px solid #fff;
							transition:0.3s ease;
							-o-transition:0.3s ease;
							-webkit-transition:0.3s ease;
							-moz-transition:0.3s ease;
							-ms-transition:0.3s ease;
							bottom:-34.5px;
						}
						
					}
				}
				li.hovered{
					> a{
						&:after{
							left:0;
							width:50px;
						}
					}
				}
			}
		}
	}
	div.search_and_langs{
		padding-top: 31px;
		img{
			
		}
		ul{
			li{
				padding-bottom: 30px;
				display: inline-block;
				vertical-align: middle;
				&:last-child{
					margin-left: 74px;
				}
				> a{
					display: inline-block;
					vertical-align: top;
					font-size: 18px;
					color: $white;
					position: relative;
				}
			}
			li.search_header{
				&:hover{
					div.search_box{
						&:after{
							left:-10px;
							width:50px;
						}
					}
				}
				div.search_box{
					position: relative;
					&:after{
						content:"";
						display:block;
						width:0%;
						position: absolute;
						left:0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						border-bottom:8px solid #fff;
						transition:0.3s ease;
						-o-transition:0.3s ease;
						-webkit-transition:0.3s ease;
						-moz-transition:0.3s ease;
						-ms-transition:0.3s ease;
						bottom:-34.5px;
					}
				}
				position: relative;
				
				img{
					cursor: pointer;
				}
				div.holder{
					display: none;
					position: absolute;
					top: 100%;
					right: 0;
					padding-top: 30px;
					input.input{
						width: 326px;
						height: 35px;
						border: 2px solid $white;
						outline: none;
						color: $white;
						font-family: $lato;
						font-size: 18px;
						padding-left: 15px;
						padding-right: 15px;
						background-color: transparent;
					}
					::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					  color: $white;
					}
					::-moz-placeholder { /* Firefox 19+ */
					  color: $white;
					}
					:-ms-input-placeholder { /* IE 10+ */
					  color: $white;
					}
					:-moz-placeholder { /* Firefox 18- */
					  color: $white;
					}
				}
			}
		}
	}
}

section.slider{
	width: 100%;
	height: 100vh;
	position: relative;
	> ul{
		@include fill;
		> li{
			@include fill;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			div.slider_content{
				padding-top: 23vh;
				width: 390px;
				position: relative;
				z-index: 2;
				div.default_font{
					position: relative;
					color: $white;
					padding: 20px;
					h2{
						text-transform: uppercase;
						//padding-bottom: 45px;
						//padding-bottom: 11%;
						padding-bottom: 7%;
						font-weight: 700;
						font-size: 38px;
						line-height: 47px;
					}
					h4{
						font-weight: 500;
					}
				}
				div.blend_active{
					&:after{
						content: '';
						display: block;
						width: 200%;
						height: 100%;
						position: absolute;
						background-color: rgba(0,0,0,0.3);
						top: 0;
						right: 0;
						z-index: -1;
					}
				}
			}
		}
	}
	div.top_cover{
		width: 100%;
		height: 44%;
		background-position: left top;
		background-image: url('./../img/top_cover.png');
		background-size: cover;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: 0;
	}
	div.slider_dots{
		position: absolute;
		bottom: 30px;
		left: 0;
		width: 100%;
		ul{
			text-align: center;
			li{
				position: relative;
				z-index: 5;
				width: 22px;
				height: 0;
				padding-bottom: 22px;
				background-color: #797981;
				border-radius: 50%;
				display: inline-block;
				vertical-align: top;
				margin-right: 19px;
				cursor: pointer;
				&:last-child{
					margin-right: 0;
				}
			}
			li.active{
				background-color: $white!important;
			}
		}
	}
}

section.square_news{
	background-color: #e6e6e6;
	padding: 4% 0;
	div.square_area{
		padding-top: 15px;
		height: 2560px;
		width: 100%;
		position: relative;
		div.square{
			height: 0;
			position: absolute;
		}
		.left_wi1{
			left: 33.33%;
		}
		.left_wi2{
			left: 66.66%;
		}
		.left_wi3{
			
		}
		.wi1{
			width: 33.33%;
		}
		.wi2{
			width:66.66%;
		}
		.wi3{
			width:99.99%;
		}
		.hi1{
			padding-bottom: 33.33%;
		}
		.hi2{
			padding-bottom: 66.66%;
		}
		.hi3{
			padding-bottom: 99.99%;
		}
		div.square_content{
			position: absolute;
			top: 15px;
			left: 15px;
			right: 15px;
			bottom: 15px;
			height: auto;
			width: auto;
		}
		div.square_content.vertical_box_inside{
			div.image{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 51.5%;
			}
			div.desc{
				position: absolute;
				height: 48.5%;
				width: 100%;
				top: 51.5%;
				left: 0;
			}
		}
		div.square_content.horizontal_box_inside{
			div.image{
				position: absolute;
				top: 0;
				left: 0;
				width: 51.5%;
				height: 100%;
			}
			div.desc{
				position: absolute;
				height: 100%;
				width: 48.5%;
				top: 0;
				left: 51.5%;
			}
		}
	}
	div.image{
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	div.desc{
		background-color: $white;
	}
	div.desc_padding{
		padding-top: 17%;
		padding-left: 43px;
		padding-right: 43px;
		div.news_header{
			margin-bottom: 3%;
			div.default_font{
				color: #222222;
				*{
					font-weight: 500;
				}
			}
		}
		div.date{
			font-size: 12px;
			color: #676767;
			margin-bottom: 9%;
		}
		> div.default_font{
			color: #676767;
			*{
				font-weight: 500;
			}
		}
	}
	div.desc_in_image{
		z-index: 2;
		position: absolute;
		left: 0;
		bottom: 0;
		bottom: 7%;
		width: 47%;
		div.desc_padding{
			padding-top: 0;
			*{
				color: $white;
			}
		}
	}
	div.cover{
		@include fill;
		background-repeat: no-repeat;
		background-position: left bottom;
		background-size: cover;
		background-image: url('./../img/news_large_image_cover.png');
	}
	a.user_friendly_click{
		z-index: 5;
	}
}

section.categories{
	width: 100%;
	.light_grey{
		background-color: #e6e6e6;
		div.default_font{
			color: #313131;
		}
		a.categories_button{
			background-color: #2c211b;
			color: $white;
		}
	}
	.medium_grey{
		background-color: #b5b5b5;
		div.default_font{
			color: #313131;
		}
		a.categories_button{
			background-color: #2c211b;
			color: $white;
		}
	}
	.dark_grey{
		background-color: #434343;
		div.default_font{
			color: $white;
			*{
				color: $white;
			}
		}
		a.categories_button{
			background-color: #e6e6e6;
			color: #313131;
		}
	}
	a.categories_button{
		display: block;
		text-align: center;
		padding: 13px 0;
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 400;
		margin-bottom: 20px;
	}
	div.categories_row{
		display: table;
		width: 100%;
		min-height: 555px;
		> div{
			display: table-cell;
			vertical-align: top;
		}
		div.categories_image{
			//width: 34%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
		div.categories_desc{
			> div{
				div.text_desc{
					margin-bottom: 4%;
				}
			}
			//width: 66%;
			padding-top: 4%;
			padding-bottom: 4%;
		}
		&:nth-child(odd){
			div.categories_padding{
				padding-left: 58px;
				padding-right: 58px;
				//padding-right: 198px;
			}
			div.categories_desc{
				width: 65.4%;
			}
		}
		&:nth-child(even){
			div.categories_padding{
				padding-left: 190px;
				padding-right: 160px;
			}
			div.categories_desc{
				width: 71.25%;
			}
		}
	}
}

footer.footer{
	div.default_font{
		color: $white;
		*{
			padding-bottom: 20px;
			font-weight: 700;
		}
	}
	nav{
		ul{
			li{
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 0;
				}
				a{
					color: $white;
					font-weight: 500;
					font-size: 16px;
					transition: 0.3s all ease;
					&:hover{
						font-weight: 700;
					}
				}
			}
		}
	}
	div.footer_top{
		padding: 5% 0 3% 0;
		background-color: #313131;
		div.social{
			padding: 5% 0 0 0;
			ul{
				text-align: center;
				lI{
					display: inline-block;
					vertical-align: top;
					margin-right: 30px;
					&:last-child{
						margin-right: 0;
					}
				}
			}
		}
	}
	div.footer_bottom{
		padding:24px 0 20px 0;
		background-color: #686868;
		color: $white;
		p{
			font-size: 16px;
		}
	}
}

//podstrony

main.content{
	background-color: #e6e6e6;;
}

section.top{
	margin-bottom: 5%;
	width: 100%;
	height: 46vh;
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	div.subpage_header{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		div.default_font{
			color: $white;
			*{
				text-transform: uppercase;
				padding-bottom: 46px;
				font-weight: 700;
			}
		}
	}
	div.top_cover{
		width: 100%;
		height: 100%;
		background-position: left top;
		background-image: url('./../img/top_cover.png');
		background-size: cover;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: 0;
	}
}

section.section_separate{
	margin-bottom: 5%;
	width: 100%;
	padding: 50px 0;
	background-repeat: no-repeat;
	background-position: left top;
	background-size: cover;
	div.default_font{
		color: $white;
		*{
			text-transform: uppercase;
			font-weight: 700;
			padding-bottom: 0;
		}
	}
}

section.only_text{
	//padding: 5% 0;
	padding-bottom: 5%;
	div.columns{
		margin-top: 5%;
		div.text_desc{
			div.image{
				width: 50%;
				margin-left: auto;
				margin-right: auto;
				text-align: center;
				margin-bottom: 50px;
				> img{
					max-width: 100%;
				}
			}
			div.default_font{
				text-align: justify;
			}
		}
	}
}

section.search{
	//padding-top: 5%;
	padding-bottom: 5%;
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: $white;
		font-family: $lato;
		font-weight: 500;
		font-size: 18px;
	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: $white;
		font-family: $lato;
		font-weight: 500;
		font-size: 18px;
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: $white;
		font-family: $lato;
		font-weight: 500;
		font-size: 18px;
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: $white;
		font-family: $lato;
		font-weight: 500;
		font-size: 18px;
	}
	div.transactions{
		margin-top: 5%;
		> ul{
			text-align: center;
			li{
				display: inline-block;
				vertical-align: top;
				width: 23%;
				margin-right: 2.6%;
				margin-bottom: 2.6%;
				&:nth-child(4n){
					margin-right: 0;
				}
				&:last-child{
					margin-right: 0;
				}
			}
		}
		div.transaction_box{
			position: relative;
			background-color: $white;
			width: 100%;
			height: 0;
			padding-bottom: 100%;
			div.t_logo{
				height: 180px;
				display: table;
				width: 200px;
				margin: 0 auto 0 auto;
				position: relative;
				top: 15px;
			}
			> div{
				text-align: center;
			}
			div.logo{
				display: table-row;
				> div{
					width: 200px;
					height: 180px;
					display: table-cell;
					vertical-align: middle;
						img{
						max-width: 100%;
						max-height: 100%;
					}
				}
			}
			div.emission{
				div.default_font{
					font-size: 14px;
					*{
						font-weight: 700;
					}
				}
			}
			div.capital{
				margin-bottom: 12px;
			}
			div.default_font{
				color: #2a2a2a;
				h1,h2,h3,h4,h5,h6,p{
					padding-bottom: 0;
					
				}
			}
			div.date{
				p{
					font-size: 14px;
					text-transform: uppercase;
					padding: 9px 20px;
					background-color: #332720;
					color: $white;
					display: inline-block;
				}
			}
			div.t_desc{
				position: absolute;
				bottom: 15px;
				left: 0;
				width: 100%;
				}
		}
	}
	div.col-md-12{
		> ul{
			text-align: center;
			li.search_holder{
				text-align: left;
				display: inline-block;
				vertical-align: top;
				width: 18%;
				margin-right: 2.5%;
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
}

div.load_more{
	text-align: center;
	padding: 4% 0 1% 0;
}

a.more_button1{
	padding: 14px 58px;
	color: $white;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 700;
	background-color: #332720;
	display: inline-block;
}

section.packets{
	padding-top: 0!important;
	padding-bottom: 6%;
	div.package_box{
		a.more_button1{
			margin: 22px 0;
		}
	}
	div.package_name{
		margin-bottom: 40px;
		p{
			display: block;
			font-size: 30px;
			padding: 26px 23px;
			font-weight: 700;
			color: $white;
			text-transform: uppercase;
			background-color: #332720;
			display: inline-block;
			&:first-child{
				padding-left: 56px;
				padding-right: 56px;
				margin-left: 9%;
			}
			&:last-child{
				padding-left: 31px;
				padding-right: 31px;
				margin-left: 22%;
			}
		}
	}
	div.package_desc{
		//margin-bottom: 15%;
		padding-top: 50px;
		//min-height: 725px;
		background-color: $white;
		padding-left: 30px;
		padding-right: 30px;
		div.text_desc{
			div.default_font{
				text-align: justify;
			}
		}
	}
}

section.career{
	> div.container{
		> div.row{
			> div.col-md-12{
				> div{
					&:last-child{
						padding-bottom: 5%;
					}
				}
			}
		}
	}
	div.career_box{
		div.career_header{
			padding: 5% 0;
			text-align: center;
			h3{
				color: #404040;
				font-size: 30px;
				line-height: 35px;
				font-weight: 700;
				text-decoration: underline;
			}
		}
	}
}

section.contact{
	padding: 4% 0;
	div.contact_header{
		padding-bottom: 40px;
		div.default_font{
			color: #303030;
			*{
				font-weight: 700;
				text-transform: uppercase;
			}
		}
	}
	div.contact_form{
		padding-bottom: 34px;
		div.holder{
			padding-bottom: 34px;
			input.input{
				width: 100%;
				padding: 0 18px;
				color: #b2b2b2;
				font-family: $lato;
				font-style: italic;
				height: 46px;
				border: none;
				font-size: 16px;
				outline: none;
			}
			textarea.textarea{
				width: 100%;
				height: 127px;
				padding: 18px;
				resize: none;
				border: none;
				outline: none;
				font-size: 16px;
				font-style: italic;
				color: #b2b2b2;
				font-family: $lato;
			}
			input.submit{
				width: 186px;
				height: 37px;
				border: none;
				font-size: 18px;
				text-transform: uppercase;
				background-color: #2c211b;
				font-family: $lato;
				color: $white;
			}
		}
		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			font-size: 16px;
			font-style: italic;
			color: #b2b2b2;
			font-family: $lato;
		}
		::-moz-placeholder { /* Firefox 19+ */
			font-size: 16px;
			font-style: italic;
			color: #b2b2b2;
			font-family: $lato;
		}
		:-ms-input-placeholder { /* IE 10+ */
			font-size: 16px;
			font-style: italic;
			color: #b2b2b2;
			font-family: $lato;
		}
		:-moz-placeholder { /* Firefox 18- */
			font-size: 16px;
			font-style: italic;
			color: #b2b2b2;
			font-family: $lato;
		}
		div.rules{
			padding-bottom: 34px;
			text-align: justify;
			p{
				font-size: 10px;
				line-height: 12px;
				color: #303030;
				font-family: $lato;
			}
		}
	}
	div.cd{
		div.contact_header{
			padding-left: 78px;
		}
	}
	div.contact_data{
		> ul{
			>li{
				padding-left: 78px;
				min-height: 47px;
				background-repeat: no-repeat;
				background-position: left center;
				margin-bottom: 11%;
				&:last-child{
					margin-bottom: 0;
				}
			}
			li.adres{
				background-image: url('./../img/contact_icon1.png');
			}
			li.phone{
				background-image: url('./../img/contact_icon2.png');
			}
			li.mail{
				background-image: url('./../img/contact_icon3.png');
			}
		}
	}
}

section.map{
	padding-bottom: 41%;
	height: 0;
	width: 100%;
	position: relative;
	iframe{
		@include fill;
	}
}

section.download{
	padding-bottom: 4%;
	table{
		width: 100%;
		tr{
			background-repeat: repeat-x;
			background-position: left bottom 21px;
			background-image: url('./../img/download_table_border.jpg');
			td{
				vertical-align: top;
				&:first-child{
					text-align: left;
					p{
						font-size: 18px;
						font-weight: 500;
						color: #373737;
						text-transform: uppercase;
						display: inline-block;
						padding-right: 9px;
						background-color: #e6e6e6;
						padding-bottom: 17px;
					}
				}
				&:last-child{
					text-align: right;
					a{
						background-color: #e6e6e6;
						font-size: 12px;
						font-weight: 500;
						padding-bottom: 6px;
						color: #868686;
						display: inline-block;
						padding-top: 4px;
						padding-left: 41px;
						background-repeat: no-repeat;
						background-position: left 6px top;
						background-image: url('./../img/pdf_icon.png');
					}
				}
			}
		}
	}
}

div.news_destails_header{
	padding-bottom: 44px;
	div.title{
		padding-bottom: 14px;
		h3{
			font-size: 36px;
			font-weight: 500;
			color: #222222;
		}
	}
	div.date{
		p{
			color: #676767;
			font-size: 14px;
		}
	}
}

section.news_details{
	div.text_desc{
		padding-bottom: 5%;
		div.default_font{
			color: #676767;
		}
	}
}

a.user_friendly_click{
	@include fill;
}

section.more_news{
	padding-bottom: 4%;
	div.mini_news{
		overflow: hidden;
		background-color: $white;
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		margin-bottom: 30px;
		> div{
			padding: 10% 6% 0 6%;
			> div.default_font{
				color: #676767;
			}
		}
		position: relative;
	}
	div.news_destails_header{
		padding-bottom: 10%;
		div.title{
			h3{
				font-size: 24px;
			}
		}
		div.date{
			p{
				font-size: 12px;
			}
		}
	}
}
/*
section.inwestor{
	padding-top: 4%;
}
*/
section.toggle_download{
	padding-bottom: 4%;
	div.text_desc{
		padding: 4% 0;
	}
	div.download_buttons{
		ul{
			li{
				margin-bottom: 27px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
		a.download_button{
			display: block;
			width: 290px;
			text-align: center;
			color: $white;
			font-size: 18px;
			font-weight: 700;
			padding: 21px 0;
			background-color: #575656;
			transition: 0.3s all ease;
			cursor: pointer;
			&:hover{
				background-color: #b5b5b5;
			}
		}
	}
	div.download_links{
		div.default_font{
			padding-bottom: 35px;
			padding-top: 35px;
			*{
				font-weight: 700;
				text-transform: uppercase;
			}
		}
		table{
			width: 100%;
			tr{
				background-repeat: repeat-x;
				background-position: left bottom 21px;
				background-image: url('./../img/download_table_border.jpg');
				td{
					vertical-align: top;
					&:first-child{
						text-align: left;
						p{
							font-size: 18px;
							font-weight: 500;
							color: #373737;
							text-transform: uppercase;
							display: inline-block;
							padding-right: 9px;
							background-color: #e6e6e6;
							padding-bottom: 17px;
						}
					}
					&:last-child{
						text-align: right;
						a{
							background-color: #e6e6e6;
							font-size: 12px;
							font-weight: 500;
							padding-bottom: 6px;
							color: #868686;
							display: inline-block;
							padding-top: 4px;
							padding-left: 41px;
							background-repeat: no-repeat;
							background-position: left 6px top;
							background-image: url('./../img/pdf_icon.png');
						}
					}
				}
			}
		}
	}
}

section.calendar_events{
	padding: 3% 0;
	ul{
		li{
			a{
				font-size: 18px;
				line-height: 30px;
				font-weight: 500;
				color: #252424;
			}
		}
	}
}

section.diagram{
	//padding-bottom: 4%;
	padding-bottom: 5%;
	div.diagram_holder{
		width: 100%;
		display: table;
		> div{
			display: table-cell;
			vertical-align: middle;
		}
		div.diagram_box{
			width: 60%;
			position: relative;
			> div{
				/*background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				width: 90%;
				height: 0;
				padding-bottom: 90%;
				margin-right: 10%;*/
			}
		}
		div.diagram_table_box{
			width: 40%;
			table{
				text-align: center;
				width: 100%;
				border-collapse:separate;
				border-spacing: 10px;
				thead{
					background-color: $white;
					td{
						p{
							color: #9d9d9c;
						}
					}
				}
				tbody{
					background-color: #9d9d9c;
					td{
						p{
							color:$white;
						}
					}
				}
				td{
					width: 33.33%;
					p{
						font-size: 14px;
						text-transform: uppercase;
						font-weight: 700;
						padding: 17px 0;
					}
				}
			}
		}
	}
	div.diagram_table{
		width: 100%;
		p{
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 700;
			padding: 17px 0;
		}
		div.row{
			div.col-md-4{
				padding-left: 5px;
				padding-right: 5px;
			}
		}
		div.table_cell{
			width: 100%;
			text-align: center;
		}
		div.table_header{
			margin-bottom: 30px;
			div.table_cell{
				background-color: $white;	
			}
			p{
				color: #9d9d9c;
			}
		}
		div.table_content{
			div.table_cell{
				margin-bottom: 14px;
				background-color: #9d9d9c;	
			}
			p{
				color:$white;
			}
		}
		div.table_footer{
			margin-top: 5px;
			div.table_cell{
				background-color: $white;	
			}
			p{
				color: #9d9d9c;
			}
		}
	}	
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
	section.diagram div.diagram_table div.row div.col-md-4{
		width: 33.33%;
		float: right;
		p{
			font-size: 10px;
		}
	}
}

section.subemision{
	padding: 5% 0;
	div.image{
		width: 100%;
		height: 0;
		padding-bottom: 70%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		margin-bottom: 20%;
	}
	div.text_desc{
		div.default_font{
			text-align: justify;
		}
	}
}

section.only_text.responsibility{
	div.text_desc{
		div.default_font{
			text-align: justify;
			p{
				padding-bottom: 5px;
			}
		}
	}
}

section.team{
	padding-bottom: 5%;
	div.team_header{
		padding-bottom: 5%;
		div.default_font{
			h1,h2,h3,h4,h5,h6,p{
				color: #424242;
				text-transform: uppercase;
				font-weight: 700;
				display: inline-block;
				border-bottom: 4px solid #424242;
			}
		}
	}
	div.team_list{
		ul{
			text-align: center;
			li{
				position: relative;
				display: inline-block;
				vertical-align: top;
				width: 16.5%;
				margin-right: 4.3%;
				margin-bottom: 4.3%;
				&:last-child{
					margin-right: 0;
				}
				&:nth-child(5n){
					margin-right: 0;
				}
				div.team_image{
					width: 100%;
					height: 0;
					padding-bottom: 100%;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					border-top-left-radius: 50px;
					border-bottom-right-radius: 50px;
					margin-bottom: 5px;
				}
				div.team_name{
					text-align: left;
					background-color: #707070;
					color: $white;
					border-bottom-left-radius: 30px;
					border-top-right-radius: 30px;
					padding-left: 22px;
					padding-right: 22px;
					padding-top: 11px;
					padding-bottom: 15px;
					h6{
						font-size: 15.88px;
						font-weight: 600;
						padding-bottom: 7px;
					}
					p{
						font-size:  13.59px;
						font-weight: 300;
					}
				}
			}
		}
	}
}

section.team.team2{
	ul{
		li{
			div.team_name{
				background-color: #313131!important;
			}
		}
	}
}

//rwd

@media only screen and (min-width: 992px) and (max-width: 10000px) {
	div.header_right{display: block!important;}
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	header.header div.menu_box nav.menu > ul > li{margin-right: 7%;}
	header.header div.menu_box nav.menu > ul > li > a{font-size: 17px;}
	header.header div.search_and_langs ul li:last-child{margin-left: 40px;}
	section.square_news div.desc_padding > div.default_font *{font-size: 13px; line-height: 23px;}
	
	div.categories_padding{padding-left: 30px!important; padding-right: 30px!important;}
	section.categories a.categories_button{font-size: 10px;}
	header.header div.search_and_langs{padding-top: 24px;}
	header.header div.menu_box nav.menu > ul > li > ul > li > a{font-size: 17px;}
	
	section.diagram div.diagram_table p{font-size: 12px;}
	
	section.search div.transactions div.transaction_box div.logo img{max-width: 80%; margin-bottom: 10px;}
	section.search div.transactions div.transaction_box div.capital div.default_font h4{font-size: 20px; line-height: 32px;}
	
	
	
	section.search div.transactions div.transaction_box div.t_logo{top: 0;}
	section.search div.transactions div.transaction_box div.logo > div{height: 60px; width: 100px;}
	
	section.more_news div.news_destails_header{padding-bottom: 4%!important;}
	section.more_news div.mini_news > div{padding: 6% 6% 0 6%!important;}
	section.more_news div.news_destails_header div.title h3{font-size: 18px!important; line-height: 30px;}
	section.more_news div.mini_news div.default_font h6{font-size: 13px!important; line-height: 28px!important;}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	header.header div.menu_box nav.menu > ul > li{margin-right: 7%;}
	header.header div.menu_box nav.menu > ul > li > a{font-size: 13px;}
	header.header div.search_and_langs ul li:last-child{margin-left: 29px;}
	section.square_news div.desc_padding > div.default_font *{font-size: 13px; line-height: 23px;}
	
	section.square_news div.desc_in_image{bottom: 2%;}
	section.square_news div.desc_padding{padding-left: 23px; padding-right: 23px; padding-top: 7%;}
	
	div.categories_padding{padding-left: 30px!important; padding-right: 30px!important;}
	section.categories a.categories_button{font-size: 10px;}
	
	header.header div.search_and_langs ul li > a{font-size: 14px;}
	header.header div.search_and_langs{padding-top: 24px;}
	header.header div.menu_box nav.menu > ul > li > ul > li > a{font-size: 14px;}
	
	section.diagram div.diagram_holder > div{display: block; width: 100%!important;}
	
	div.search_table > ul li{width: 50%!important;}
	
	section.team div.team_list ul li div.team_name h6{font-size: 12.88px;}
	section.team div.team_list ul li div.team_name p{font-size: 9.59px;}
	section.team div.team_list ul li{width: 17.5%; margin-right: 3%;}
	
	section.search div.transactions div.transaction_box div.t_logo{height: 60px; width: 100px;}
	section.search div.transactions div.transaction_box div.logo > div{height: 30px; width: 50px;}
	
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	section.search div.transactions > ul li{width: 48%; margin-right: 4%; margin-bottom: 4%;}
	section.search div.transactions > ul li:nth-child(2n){margin-right: 0;}
	
	section.team div.team_list ul li{width: 48%; margin-right: 4%; margin-bottom: 4%;}
	section.team div.team_list ul li:nth-child(2n){margin-right: 0;}
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	section.search div.transactions > ul li{width: 100%; margin-right: 0%; margin-bottom: 30px;}
	section.team div.team_list ul li{width: 100%; margin-right: 0; margin-bottom: 30px;}
	section.team div.team_list ul li:nth-child(2n){margin-right: 0;}
	
	section.search div.transactions > ul li{width: 48%; margin-right: 4%; margin-bottom: 4%;}
	section.search div.transactions > ul li:nth-child(2n){margin-right: 0;}
	section.search div.transactions div.transaction_box div.t_logo{height: 100px;}
	section.search div.transactions div.transaction_box div.logo > div{height: 100px;}
	
	div.text_desc div.default_font {overflow-x: scroll;}
	
	section.diagram div.diagram_holder div.diagram_box{display: none!important;}
	
	section.slider > ul > li div.slider_content{padding-top: 0!important;}
	section.slider > ul > li div.slider_content div.default_font h2{font-size: 24px; line-height: 33px}
	section.slider > ul > li div.slider_content div.default_font h3{font-size: 21px; line-height: 30px}
	section.slider > ul > li div.slider_content div.default_font h4{font-size: 16px; line-height: 28px;}
	section.slider > ul > li div.slider_content div.default_font h5{font-size: 14px; line-height: 26px;}
	section.slider > ul > li div.slider_content div.default_font h6{font-size: 11px; line-height: 22px;}
	section.slider div.slider_dots ul li{width: 18px; padding-bottom: 18px; margin-right: 14px;}
	section.slider > ul > li div.slider_content div.default_font {font-size: 9px; line-height: 21px;}
	section.slider > ul > li div.slider_content div.blend_active:after{width: 100%;}
}

@media only screen and (min-width: 320px) and (max-width: 553px) {
	section.search div.transactions > ul li{width: 100%; margin-bottom: 4%;}
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
	header.header{overflow: visible;}
	div.logo_box img{width: 50px; height: auto; position: relative; z-index: 6;}
	header.header div.menu_box nav.menu > ul{text-align: center;}
	header.header div.menu_box nav.menu > ul > li{margin-right: 0; padding-bottom: 15px; width: 100%;}
	header.header div.menu_box nav.menu > ul > li > a{font-size: 15px;}
	header.header div.menu_box nav.menu > ul > li > ul{display: block!important; position: relative; width: 100%!important;}
	header.header div.menu_box nav.menu > ul > li > ul > li{margin-right: 0; margin-bottom: 10px; width: 100%;}
	header.header div.menu_box nav.menu > ul > li > ul > li > a{font-size: 12px;}
	div.header_right{display: none; position: fixed; width: 100%; height: 100vh; background-color: #434343; top: 0; left: 0;}
	header.header div.search_and_langs{text-align: center;}
	header.header div.search_and_langs img{margin-right: 20px;}
	div.rwd_button{position: absolute; top: 0; right: 0; width: 30px; height: 30px; z-index: 6;}
	div.rwd_button > div{width: 100%; height: 3px; position: absolute; background-color: $white; transition: 0.3s all ease;}
	div.rwd_button > div:last-child{margin-bottom: 0;}
	
	div.menu_box nav.menu{padding-top: 15vh;}
	header.header div.menu_box nav.menu > ul:after{display: none;}
	header.header div.menu_box nav.menu > ul > li > a:after{display: none;}
	
	
	section.categories div.categories_row{display: flex; flex-flow: column; min-height: auto;}
	section.categories div.categories_row div.categories_image{height: 0; padding-bottom: 100%; order: 1; align-self: stretch;}
	section.categories div.categories_row div.categories_desc{width: 100%!important; order: 2; align-self: stretch;}
	div.categories_padding{padding-left: 15px!important; padding-right: 15px!important;}
	
	section.square_news div.square_area{height: auto;}
	section.square_news div.square_area div.square{position: relative!important; top: auto!important; padding-bottom: 100%!important; height: auto; width: 100%!important; left: 0!important; margin-top: 0!important; margin-bottom: 30px;}
	section.square_news div.square_area div.square_content{top: auto!important; left: auto!important; right: auto!important; bottom: auto!important; height:100%!important; width: 100%!important;}
	div.horizontal_box_inside > div{height: 100%!important; width: 100%!important; top: 0!important; left: 0!important;}
	div.horizontal_box_inside div.desc{background-color: rgba(0,0,0,0.5)!important;}
	div.horizontal_box_inside div.desc div.default_font{color: $white!important;}
	div.vertical_box_inside > div{height: 100%!important; width: 100%!important; top: 0!important; left: 0!important;}
	div.vertical_box_inside div.desc{background-color: rgba(0,0,0,0.5)!important;}
	div.vertical_box_inside div.desc div.default_font{color: $white!important;}
	section.square_news div.desc_in_image{bottom: auto!important; width: 100%!important;}
	section.square_news div.desc_in_image div.desc_padding{padding-top: 17%;}
	section.square_news div.vertical_box_inside div.desc_padding div.date{color: $white!important;}
	section.square_news div.horizontal_box_inside div.desc_padding div.date{color: $white!important;}
	
	section.section_separate div.default_font{text-align: center;}
	div.default_font h2{font-size: 25px; line-height: 35px;}
	
	.sol-current-selection{margin-top: 30px!important;}
	
	div.columns > div.col-md-4{margin-bottom: 30px;}
	
	section.contact div.cd div.contact_header{padding-left: 0; text-align: center;}
	section.contact div.contact_data > ul > li{padding-left:0; padding-top: 78px; background-position: center top; text-align: center;}
	
	section.download table tr{background-image: none;}
	section.download table tr td:first-child p{font-size: 10px;}
	
	section.packets div.package_name p{display: block; width: 100%;}
	section.packets div.package_name p{margin-left: 0!important; padding-left: 20px!important; padding-right: 20px!important;}
	div.package_box{margin-bottom: 30px;}
	
	section.diagram div.diagram_holder{display: block;}
	section.diagram div.diagram_holder div.diagram_box{width: 100%; display: block;}
	section.diagram div.diagram_holder div.diagram_box > div{margin: 0 auto 30px auto;}
	section.diagram div.diagram_holder div.diagram_table_box{width: 100%; display: block;}
	
	section.toggle_download div.download_buttons a.download_button{width: auto;}
	section.toggle_download div.download_links table tr td:first-child p{font-size: 10px;}
	section.toggle_download div.download_links table tr{background-image: none;}
	
	section.more_news div.mini_news{margin-bottom: 30px;}
	
	footer.footer{text-align: center;}
	footer.footer nav ul li:last-child{margin-bottom: 40px;}
	
	header.header div.search_and_langs ul li.search_header{display: none;}
	header.header div.search_and_langs ul li:last-child{margin-left: 0;}
	
	section.slider > ul > li div.slider_content{width: auto; text-align: center;}
	section.top div.subpage_header div.default_font{text-align: center;}
	
	div.search_table > ul li{width: 100%!important;}
	div.search_table > ul li > a{font-size: 13px!important;}
	
	section.search div.col-md-12 > ul li.search_holder{width: 100%; margin-right: 0; margin-bottom: 15px;}
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
	section.square_news div.desc_padding{padding-left: 10px; padding-right: 10px;}
	section.square_news div.desc_padding > div.default_font h6{font-size: 10px; line-height: 20px;}
	
	section.more_news div.news_destails_header{padding-bottom: 4%!important;}
	section.more_news div.mini_news > div{padding: 6% 6% 0 6%!important;}
	section.more_news div.news_destails_header div.title h3{font-size: 18px!important; line-height: 30px;}
	section.more_news div.mini_news div.default_font h6{font-size: 13px!important; line-height: 28px!important;}
}

.scroll_block{
	overflow: hidden;
}

#nav-icon4 {
}

.rwd_button div:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.rwd_button div:nth-child(2) {
  top: 14px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.rwd_button div:nth-child(3) {
  top: 27px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.rwd_button.open div:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 2px;
  left: 0;
}

.rwd_button.open div:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.rwd_button.open div:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 23px;
  left: 0;
}

div.menu_button{
	position: absolute;
	height: 30px;
	width: 30px;
	right: 0;
	top: 15px;
	transition: 0.3s all ease;
	.arr{
		display: none;
		position: absolute;
		height: 100%;
		width: 100%;
		top: 100%;
		left: -4px;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url('./../img/menu_arr_down.png');
		background-size: contain;
		z-index: 5;
	}
}
text{
	font-size: 17px;
}

div.search_table{
	> ul{
		display: inline-block;
		border-left: 1px solid #332720;
		min-width: 100%;
		li{
			display: inline-block;
			vertical-align: top;
			width: 33.33%;
			border-right: 1px solid #332720;
			border-top: 1px solid #332720;
			border-bottom: 1px solid #332720;
			text-align: center;
			transition: 0.3s all ease;
			padding: 0 20px;
			&:hover{
				background-color:#332720;
				> a{
					color: $white;
				}
			}
			> a{
				display: block;
				padding: 20px 0;
				color: #332720;
				transition: 0.3s all ease;
				font-size: 15px;
			}
		}
	}
}

header.desc_header{
	padding-bottom: 27px;
	> div.default_font{
		display: inline-block;
		padding-bottom: 14px;
		margin-bottom: 14px;
		border-bottom: 4px solid #424242;
		h1,h2,h3,h4,h5,h6,p{
			text-transform: uppercase;
			font-weight: 700;
		}
	}
	> div.text-desc{
		> div.default_font{
			h1,h2,h3,h4,h5,h6,p{
				font-weight: 500;
			}
		}
	}
}

section.team_more{
	padding-bottom: 5%;
	div.team_more_image{
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		border-top-left-radius: 50px;
    	border-bottom-right-radius: 50px;
	}
	div.team_more_desc{
		div.default_font{
			p{
				padding-bottom: 10px;
			}
			padding-bottom: 27px;
			&:last-child{
				padding-bottom: 0;
			}
			p{
				font-weight: 500;
			}
		}
	}
	div.default_font{
		color: #424242;
	}
}

div.publication{
	padding-top: 30px;
	div.default_font{
		padding-bottom: 10px;
	}
	> div{
		display: inline-block;
		vertical-align: middle;
		> div{
			display: inline-block; 
			vertical-align: top;
			margin-right: 10px;
			&:last-child{
				margin-right: 0;
			}
		}
		img{
			width: 45px; height: auto;
		}
		&:first-child{
			margin-right: 10px;
		}
	}
}
